import { ColourVariantWithTheme } from '../../design-system/types/colour-variant'
import { FrameworkState } from '../../types/entities'
import {
  Buildings,
  Globe,
  CircleDashed,
  CircleHalf,
  Icon,
  IconWeight,
} from '@phosphor-icons/react'

export type StatusOption = {
  title: string
  variant: ColourVariantWithTheme
  icon: Icon
  iconWeight?: IconWeight
  iconClassName?: string
  description: string
}

export class TeamStateVm {
  constructor(private orgName?: string) {}

  get statusOptions(): Record<FrameworkState, StatusOption> {
    return {
      in_progress: {
        title: 'In Progress',
        variant: 'paper' as ColourVariantWithTheme,
        icon: CircleDashed,
        iconClassName: 'text-gray-600',
        description: 'Visible to admins, and editors of this team',
      },
      in_review: {
        title: 'In Review',
        variant: 'orange' as ColourVariantWithTheme,
        icon: CircleHalf,
        iconWeight: 'fill',
        iconClassName: 'text-orange-500',
        description: 'Visible to admins, and editors of this team',
      },
      published: {
        title: 'Live',
        variant: 'success' as ColourVariantWithTheme,
        icon: Buildings,
        iconClassName: 'text-green-600',
        description: `Visible to everyone at ${this.orgName}`,
      },
      publicly_accessible: {
        title: 'Live (Public)',
        variant: 'success' as ColourVariantWithTheme,
        icon: Globe,
        iconClassName: 'text-green-600',
        description: 'Share team to the internet.',
      },
    }
  }
}
